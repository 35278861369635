
import * as initialStates from '../constants/InitialStates';
import * as actionTypes from "../constants/ActionTypes";

export default function deliveryAddress(state = initialStates.REGISTER_INITIAL_STATE , action) {
  switch (action.type) {
    
    case actionTypes.UPDATE_REGISTER_INFO:
      const { name, value } = action.data
      return {
        ...state, 
        [name]: value
      }

    case actionTypes.UPDATE_VALIDATION_ERROR:
			return {
				...state,
				validationError: [...action.data]
			}

		case actionTypes.RESET_VALIDATION_ERROR:
			return {
				...state,
				validationError: []
      }
      
    case actionTypes.REMOVE_INPUT_ERROR:
      const error = state.validationError.filter(data => {
        return data !== action.data
      })
      return {
        ...state,
        validationError: error
      }

    case actionTypes.HIDE_REGISTER_PANEL:
      return initialStates.REGISTER_INITIAL_STATE;

    case actionTypes.CHECKBOX_ERROR_PRESENT:
      return {
        ...state,
        istncCheckboxError: true,
      };
    
    case actionTypes.CHECKBOX_ERROR_NOT_PRESENT:
      return {
        ...state,
        istncCheckboxError: false,
      };

    case actionTypes.TOGGLE_CHECKBOX:
      return {
        ...state,
        isTncChecked: !state.isTncChecked,
      };

    case actionTypes.SHOW_LOGIN_PAGE:
      return {
        ...state,
        showLoginPage: true,
        showRegisterPage: false,
        showForgetPasswordPage: false,
        showResetPasswordPage: false
      };

    case actionTypes.SHOW_REGISTER_PAGE:
      return {
        ...state,
        showLoginPage: false,
        showRegisterPage: true
      };

    case actionTypes.SHOW_FORGOTPWD_PAGE:
      return {
        ...state,
        showLoginPage: false,
        showRegisterPage: false,
        showForgetPasswordPage: true
      };

    case actionTypes.SHOW_RESET_PWD_PAGE:
      return {
        ...state,
        showLoginPage: false,
        showRegisterPage: false,
        showForgetPasswordPage: false,
        showResetPasswordPage: true
      };

    case actionTypes.REGISTRATION_IN_PROGRESS:
      return {
        ...state,
        registrationInProgress: true
      };

    case actionTypes.REGISTRATION_CALL_COMPLETE:
      let response = action.data,
      isSuccess = response && response.status && response.status.status == "SUCCESS" ? true : false,
      isError = response && response.status && response.status.status == "ERROR" ? true : false;

      if (isSuccess) {
        return Object.assign({}, state, {
          registrationFlowComplete: true,
          registrationInProgress: false,
          registrationAttemptFailed: false,
        });
      }
      else if (isError) {
        return Object.assign({}, state, {
          registrationFlowComplete: false,
          registrationInProgress: false,
          registrationAttemptFailed: true,
        });
      }
      return Object.assign({}, state, {
        registrationFlowComplete: false,
        registrationInProgress: false,
        registrationAttemptFailed: true,
      });
      
    default:
      return state;
  }
}

