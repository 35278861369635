import React, { useState } from "react";
import { validateForgetPwdForm } from "../../helpers/Utils";

import "../../../../assets/stylesheets/login/login.scss";

function ForgotPassword(props) {

  const { actions, loginData } = props;
  const { validationError } = props.loginData;

  const translationsData = window.panelConfig.goPartsTranslations.forgotPassword;

  function setInputState(name, value) {
    const data = {
      name,
      value,
    };
    actions.loginActions.removeInputError(name);
    actions.loginActions.updateLoginInfo(data);
  }

  function closeRegisterPanel() {
    actions.registerActions.hideRegisterPanel(false);
  }

  function showLoginPage() {
    actions.registerActions.showLoginPage();
  }

  function handleInputChange(event) {
    setInputState(event.target.name, event.target.value);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      forgotPasswordCTA();
    }
  }

  function forgotPasswordCTA() {
    const errors = validateForgetPwdForm(loginData);
    if (errors.length) {
      actions.loginActions.updateValidationError(errors);
    }
    else {
      actions.loginActions.tryForgotPassword(loginData);
    }
  }

  return (
    <div className="login-ui-container">
      <div className="cross-container cp" onClick={e => closeRegisterPanel()}>
        <img height="30px" src="https://d3brnpc5nhvc9v.cloudfront.net/site/static-images/Close-Button.png" alt="Group-20133" border="0" />
      </div>
      <div className="register-field-container">
        <p className="gp-text24 gp-mb20 gp-lh30">
          { translationsData.forgotPassword }
        </p>
        <p className="gp-text16 gp-636363 gp-lh162  gp-mb20">
          { translationsData.forgetInst }
        </p>
        {!loginData.forgotPWDFlowComplete && < div className={"floating-label input-group "}>
          <input
            name="email"
            type="text"
            placeholder={ translationsData.emailPlaceholder }
            className={`common-input floating-input ${validationError.includes('email') ? "error-input" : "correct-input"}`}
            onChange={handleInputChange}
            autoComplete="false"
            onKeyPress={handleKeyPress}
          />
        </div>}
        {loginData.forgotPWDAttemptFailed && (
          <div className="checkbox-error tc gp-mt20 gp-mb20">
            <span className="error-text gp-text14 gp-FE696E">
              { translationsData.forgetAttemptFailed }
            </span>
          </div>
        )}
        {loginData.forgotPWDFlowComplete && (
          <div className="checkbox-error tc gp-mt20 gp-mb20">
            <span className="error-text gp-text14 gp-FE696E">
              { translationsData.mailSent }
            </span>
          </div>
        )}
        {!loginData.forgotPWDFlowComplete && <div className="signup-container cp" onClick={() => forgotPasswordCTA()}>
          {
            loginData.forgotPasswordInProgress ? (
              <span className="panel-sprite  white-loader"></span>
            ) : (
              <span className="ibm tc"> { translationsData.passwordCTA } </span>
            )
          }
        </div>}
        <div className="sigin-link gp-mt20 tc gp-text16 gp-f400 gp-lh19" onClick={showLoginPage}>
          <span>{ translationsData.backTo } </span> <span className="gp-1E8EA5 cp">{ translationsData.login }</span>
        </div>
      </div>
      <div className="copyright-container gp-text12 gp-636363 d-flex">
        <span>Copyright © 2021 GoParts Technology GmbH | Privacy Terms & Conditions</span>
      </div>
    </div>
  );
}

export default ForgotPassword;
