import React, { useState } from "react";

function RegisterInput(props) {
  const { inputData, setInputState, validationError } = props;
  const { name, type, placeholder, styles, width, value, isRequired } = inputData;
  const widthStyle = width ? width : "";
  const [isPasswordToggled, setPasswordToggled] = useState(false)

  function handleInputChange(event) {
    setInputState(event.target.name, event.target.value);
  }

  function updatePasswordToggledState() {
    setPasswordToggled(!isPasswordToggled)
  }

  function getInputType(currentType) {
    if (currentType != 'password') {
      return currentType
    } else {
      if (isPasswordToggled) {
        return 'text'
      } else {
        return 'password'
      }
    }
  }

  return (
    <>
      <div className={"floating-label input-group " + widthStyle}>
        <input
          type={getInputType(type)}
          name={name}
          placeholder=" "
          className={`floating-input ${styles} ${validationError ? "error-input" : "correct-input"}`}
          value={value}
          onChange={handleInputChange}
          autoComplete="off"
        />
        {name === 'password' && <span onClick={(e) => updatePasswordToggledState()}
          className={`fa fa-eye eye-icon cp ${isPasswordToggled ? "gp-1E8EA5" : "gp-636363"}`}>
        </span>}
        <label>{placeholder}
          {isRequired && <span className="gp-FE696E gp-ml4">*
          </span>}
        </label>
      </div>
    </>
  );
}

export default RegisterInput;
