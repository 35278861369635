import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import LoginApp from '../app/containers/loginApp';
import configurePanelStore from '../app/store/configureLoginStore';


const store = configurePanelStore();


render(
  <Provider store={store}>
    <LoginApp/>
  </Provider>,
  document.getElementById('root')
);