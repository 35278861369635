export const registerInputListGenerator = (state) => {
	return [
		{
			name: 'email',
			type: 'text',
			placeholder: window.panelConfig.goPartsTranslations.register.email,
			styles: 'common-input',
			value: state.email,
      isRequired: true
		},
    {
			name: 'password',
			type: 'password',
			placeholder: window.panelConfig.goPartsTranslations.register.password,
			styles: 'common-input',
			value: state.password
		},
    {
			name: 'name',
			type: 'text',
			placeholder: window.panelConfig.goPartsTranslations.register.name,
			styles: 'common-input',
			value: state.name,
      isRequired: true
		},
    {
			name: 'phone',
			type: 'number',
			placeholder: window.panelConfig.goPartsTranslations.register.phone,
			styles: 'common-input',
			value: state.phone
		},
    {
			name: 'comp_name',
			type: 'text',
			placeholder: window.panelConfig.goPartsTranslations.register.compName,
			styles: 'common-input',
			value: state.comp_name,
      isRequired: true
		},
    {
			name: 'address',
			type: 'text',
			placeholder: window.panelConfig.goPartsTranslations.register.address,
			styles: 'common-input',
			value: state.address,
      isRequired: true
		},
    {
			name: 'plz',
			type: 'text',
			placeholder: window.panelConfig.goPartsTranslations.register.plz,
			styles: 'common-input',
			value: state.plz,
      width: 'half-input',
      isRequired: true
		},
    {
			name: 'city',
			type: 'text',
			placeholder: window.panelConfig.goPartsTranslations.register.city,
			styles: 'common-input',
      width: 'half-input left-gap',
			value: state.city,
      isRequired: true
		},
    {
			name: 'country',
			type: 'text',
			placeholder: window.panelConfig.goPartsTranslations.register.country,
			styles: 'common-input',
			value: state.country
		}
	]
}