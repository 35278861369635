import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as loginActions from '../actions/login';
import * as registerActions from '../actions/register';
import Register from '../components/register/register';
import Login from '../components/login/login'
import ForgotPassword from '../components/forgotPassword/ForgotPassword';
import ResetPassword from '../components/resetPassword/ResetPassword';
import RegistrationSuccess from '../components/registrationSuccess/registrationSuccess'
import { updateDefaultLanguageInCookies, openCalendlyView } from "../helpers/Utils";

import * as siteConstants from '../constants/SiteConstants';

import '../../../assets/stylesheets/style_guide/index.scss'
import "../../../assets/stylesheets/sprites/panel_sprite.scss";
import "../../../assets/stylesheets/style_guide/animation.scss";

function LoginApp(props) {
  const { actions, loginData, registerData } = props;
  const translationsData = window.panelConfig.goPartsTranslations.loginApp;
  const cookies = new Cookies();
  const [languageDropdown, setLanguageDropdown] = useState('DE');
  
  useEffect(() => {
    handleSubViewFromRoute(window.panelConfig.appSubView)
  }, [window.panelConfig.appSubView]);

  useEffect(() => {
    handleParentViews()
  }, [registerData.showRegisterPage, registerData.showLoginPage, registerData.showForgetPasswordPage]);

  useEffect(() => {
    document.addEventListener('click', checkForRegistration);
    return () => {
      document.removeEventListener('click', checkForRegistration);
    }
  }, []);

  useEffect(() => {
    let cookiesLang = cookies.get('defaultLanguage');
    if (!cookiesLang) {
      setLanguageDropdown('DE');
    }
    else {
      switch (cookiesLang) {
        case 'en':
          setLanguageDropdown('EN');
          break;
        case 'de':
          setLanguageDropdown('DE');
          break;
        default:
          setLanguageDropdown('DE');
      }
    }
  }, [])

  function handleSubViewFromRoute(appSubView) {
    if(appSubView) {
      switch (appSubView) {
        case siteConstants.LOGIN_SUB_VIEW:
          actions.registerActions.showLoginPage();
          break;
        case siteConstants.RESET_PASSWORD_SUB_VIEW:
          actions.registerActions.showResetPasswordPage();
          break;
        default:
          window.open(location.origin, "_self");
          break;
      }
    }
  }

  function checkForRegistration(event) {
    if (event && event.target && event.target.classList && event.target.classList.length &&
      event.target.classList.contains('registration-button') &&
      !(registerData.showRegisterPage)) {
      showRegisterPage()
    }
  }

  function handleParentViews() {
    if (registerData && (registerData.showLoginPage || registerData.showRegisterPage ||
      registerData.showForgetPasswordPage)) {
      document.body.classList.add('blur-background');
    } else {
      document.body.classList.remove('blur-background');
    }
  }

  function showRegisterPage() {
    props.actions.registerActions.showRegisterPage();
  }

  function showLoginPage() {
    props.actions.registerActions.showLoginPage();
  }

  return (
    <div className="login-app-container gp-f500 gp-lh14">
      <div className="header-right-wrapper d-flex">
        <div className="gp-mr16 gp-mt8 cp menu-link gp-text16 gp-f400 gp-202020 gp-lh162" onClick={showLoginPage}>
          {translationsData.register}
        </div>
        <div className="dropdown gp-mr20">
          <div className="dropbtn cp d-flex">
            <span className="gp-f400">{languageDropdown}</span> <span className="fa fa-angle-down dropdown-arrow lang-dropdown-icon gp-text21"></span>
          </div>
          <div className="dropdown-content">
            <a href="/" className="lang-wrapper d-flex cp" onClick={() => updateDefaultLanguageInCookies('en')}>
              <span><img src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png" height="20px" /></span>
              <span className="gp-ml12 gp-636363">English</span>
            </a>
            <hr className="gp-mt12 gp-mb12" />
            <a href="/" className="lang-wrapper gp-mt8 d-flex cp" onClick={() => updateDefaultLanguageInCookies('de')}>
              <span><img src="https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/1200px-Flag_of_Germany.svg.png" height="20px" /></span>
              <span className="gp-ml12 gp-636363">Deutsch</span>
            </a>
          </div>
        </div>
      </div>
      {registerData && registerData.showRegisterPage &&
        <Register
          actions={actions}
          loginData={loginData}
          registerData={registerData}
        />}

      {registerData && registerData.showLoginPage &&
        <Login
          actions={actions}
          loginData={loginData}
          registerData={registerData}
        />}

      {registerData && registerData.showForgetPasswordPage &&
        <ForgotPassword
          actions={actions}
          loginData={loginData}
          registerData={registerData}
        />}

      {registerData && registerData.showResetPasswordPage &&
        <ResetPassword
          actions={actions}
          loginData={loginData}
          registerData={registerData}
        />}

      {registerData && registerData.registrationFlowComplete &&
        <RegistrationSuccess
          actions={actions}
        />}
    </div>
  );
};

LoginApp.propTypes = {
  actions: PropTypes.object.isRequired
};


function mapStateToProps(state) {
  return {
    loginData: state.login,
    registerData: state.signup
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loginActions: bindActionCreators(loginActions, dispatch),
      registerActions: bindActionCreators(registerActions, dispatch)
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginApp);
