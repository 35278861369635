
import * as initialStates from '../constants/InitialStates';
import * as actionTypes from "../constants/ActionTypes";


export default function login(state = initialStates.LOGIN_INITIAL_STATE, action) {
  let apiResponse,
    responseData,
    isSuccess,
    stateObj;

  switch (action.type) {

    case actionTypes.UPDATE_LOGIN_INFO:
      const { name, value } = action.data
      return {
        ...state,
        [name]: value
      }

    case actionTypes.UPDATE_VALIDATION_ERROR:
      return {
        ...state,
        validationError: [...action.data]
      }

    case actionTypes.RESET_VALIDATION_ERROR:
      return {
        ...state,
        validationError: []
      }

    case actionTypes.REMOVE_INPUT_ERROR:
      const error = state.validationError.filter(data => {
        return data !== action.data
      })
      return {
        ...state,
        validationError: error
      }

    case actionTypes.LOGIN_IN_PROGRESS:
      return {
        ...state,
        loginInProgress: true
      };

    case actionTypes.FORGET_PWD_IN_PROGRESS:
      return {
        ...state,
        forgotPasswordInProgress: true
      };

    case actionTypes.RESET_IN_PROGRESS:
      return {
        ...state,
        resetPwdInProgress: true,
        resetFlowComplete: false,

      };
    case actionTypes.LOGIN_CALL_COMPLETE:
      let response = action.data,
        isSuccess = response && response.status && response.status.status == "SUCCESS" ? true : false,
        isError = response && response.status && response.status.status == "ERROR" ? true : false;
      if (isSuccess) {
        return Object.assign({}, state, {
          loginFlowComplete: true,
          loginInProgress: false,
          loginAttemptFailed: false,
        });
      }
      else if (isError) {
        return Object.assign({}, state, {
          loginFlowComplete: false,
          loginInProgress: false,
          loginAttemptFailed: true,
        });
      }
      return Object.assign({}, state, {
        loginFlowComplete: false,
        loginInProgress: false,
        loginAttemptFailed: true,
      });

    case actionTypes.FORGET_PWD_CALL_COMPLETE:
        apiResponse = action.data,
        isSuccess = apiResponse && apiResponse.status && apiResponse.status.status == "SUCCESS" ? true : false,
        isError = apiResponse && apiResponse.status && apiResponse.status.status == "ERROR" ? true : false;
      if (isSuccess) {
        return Object.assign({}, state, {
          forgotPasswordInProgress: false,
          forgotPWDAttemptFailed: false,
          forgotPWDFlowComplete: true,
        });
      }
      else if (isError) {
        return Object.assign({}, state, {
          forgotPWDFlowComplete: false,
          forgotPasswordInProgress: false,
          forgotPWDAttemptFailed: true,
        });
      }
      return Object.assign({}, state, {
        forgotPWDFlowComplete: false,
        forgotPasswordInProgress: false,
        forgotPWDAttemptFailed: true,
      });

    case actionTypes.RESET_CALL_COMPLETE:
      response = action.data;
      isSuccess = response && response.status && response.status.status == "SUCCESS" ? true : false;

      if (isSuccess) {
        return Object.assign({}, state, {
          resetAttemptFailed: false,
          resetFlowComplete: true,
          resetPwdInProgress: false,
        });
      }
      else {
        return Object.assign({}, state, {
          resetAttemptFailed: true,
          resetFlowComplete: false,
          resetPwdInProgress: false
        });
      }

    case actionTypes.LOGOUT_COMPLETE:
      return initialStates.LOGIN_INITIAL_STATE;

    default:
      return state;
  }
}