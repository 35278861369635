/*-- Register Action Types --*/

export const SHOW_LOGIN_UI = "SHOW_PARTS_SELECTION";
export const UPDATE_REGISTER_INFO = "UPDATE_REGISTER_INFO";
export const UPDATE_VALIDATION_ERROR = "UPDATE_VALIDATION_ERROR";
export const RESET_VALIDATION_ERROR = "RESET_VALIDATION_ERROR";
export const REMOVE_INPUT_ERROR = "REMOVE_INPUT_ERROR";
export const HIDE_REGISTER_PANEL = "HIDE_REGISTER_PANEL";
export const CHECKBOX_ERROR_NOT_PRESENT = "CHECKBOX_ERROR_NOT_PRESENT";
export const CHECKBOX_ERROR_PRESENT = "CHECKBOX_ERROR_PRESENT";
export const TOGGLE_CHECKBOX = "TOGGLE_CHECKBOX";
export const SHOW_LOGIN_PAGE = "SHOW_LOGIN_PAGE";
export const SHOW_REGISTER_PAGE = "SHOW_REGISTER_PAGE";
export const SHOW_FORGOTPWD_PAGE = "SHOW_FORGOTPWD_PAGE";
export const SHOW_RESET_PWD_PAGE = "SHOW_RESET_PWD_PAGE";
export const REGISTRATION_IN_PROGRESS = "REGISTRATION_IN_PROGRESS";
export const REGISTRATION_CALL_COMPLETE = "REGISTRATION_CALL_COMPLETE";
export const BACK_TO_DASHBOARD = 'BACK_TO_DASHBOARD';

/* Login Action Types  ----*/
export const UPDATE_LOGIN_INFO = "UPDATE_LOGIN_INFO";
export const LOGIN_IN_PROGRESS = "LOGIN_IN_PROGRESS";
export const LOGIN_CALL_COMPLETE = "LOGIN_CALL_COMPLETE";
export const FORGET_PWD_IN_PROGRESS = "FORGET_PWD_IN_PROGRESS";
export const FORGET_PWD_CALL_COMPLETE = "FORGET_PWD_CALL_COMPLETE";
export const LOGOUT_COMPLETE                  = "LOGOUT_COMPLETE";
export const RESET_IN_PROGRESS                = "RESET_IN_PROGRESS";
export const RESET_CALL_COMPLETE              = "RESET_CALL_COMPLETE";


/* Sidebar Action Types  ----*/
export const SHOW_SERVICE_OVERVIEW = "SHOW_SERVICE_OVERVIEW";
export const SHOW_PARTS_CHECKER = "SHOW_PARTS_CHECKER";
// export const SHOW_PARTS_IDENTIFIER = "SHOW_PARTS_IDENTIFIER";
export const SHOW_PARTS_IDENTIFIER = "SHOW_PARTS_IDENTIFIER";
export const SHOW_VEHICLE_LINKER = "SHOW_VEHICLE_LINKER";
export const SHOW_PARTS_FINDER = 'SHOW_PARTS_FINDER';
export const SHOW_PART_LINKER_1 = 'SHOW_PART_LINKER_1';
export const SHOW_OE_PAPER_READOUT = "SHOW_OE_PAPER_READOUT"
export const SHOW_NOTIFICATIONS = "SHOW_NOTIFICATIONS";
export const SHOW_SETTINGS = "SHOW_SETTINGS";
export const SHOW_PARTS_PAGE = "SHOW_PARTS_PAGE";

/*-- Parts Action Types --*/
export const SHOW_PARTS_LOADER                = "SHOW_PARTS_LOADER";
export const HIDE_PARTS_LOADER                = "HIDE_PARTS_LOADER";
export const HIDE_GRAPHIC_SEARCH              = "HIDE_GRAPHIC_SEARCH";
export const SHOW_PARTS_HOME_PAGE             = "SHOW_PARTS_HOME_PAGE";
export const SHOW_PARTS_RESULT_PAGE           = "SHOW_PARTS_RESULT_PAGE";

export const UPDATE_PART_SEARCH_INPUT         = "UPDATE_PART_SEARCH_INPUT";
export const RESET_PART_DD                    = "RESET_PART_DD";
export const SHOW_BRAND_DD                    = "SHOW_BRAND_DD";
export const UPDATE_SEARCH_BRAND              = "UPDATE_SEARCH_BRAND";

export const PART_SEARCH_IN_PROGRESS          = "PART_SEARCH_IN_PROGRESS";
export const UPDATE_PART_SEARCH_OBJECT        = "UPDATE_PART_SEARCH_OBJECT";
export const ENABLE_PARTS_RESULT_LISTING      = "ENABLE_PARTS_RESULT_LISTING";
export const DISABLE_PARTS_RESULT_LISTING     = "DISABLE_PARTS_RESULT_LISTING";
export const RESET_PART_SEARCH                = "RESET_PART_SEARCH";
export const SHOW_PART_SELECTOR               = "SHOW_PART_SELECTOR";
export const HIDE_PART_SELECTOR               = "HIDE_PART_SELECTOR";
export const UPDATE_SELECTED_PART_DATA        = "UPDATE_SELECTED_PART_DATA"

export const PART_BRAND_IDENTIFY_SEARCHED     = "PART_BRAND_IDENTIFY_SEARCHED";
export const PART_VALIDATING_FIN              = "PART_VALIDATING_FIN";
export const PART_FIN_VALIDATED               = "PART_FIN_VALIDATED";
export const PART_UPDATE_VECHILE_INFO_AND_ENGINE_CODES    = "PART_UPDATE_VECHILE_INFO_AND_ENGINE_CODES";
export const PART_SET_FIN_AND_SERVICE_TYPE    = "PART_SET_FIN_AND_SERVICE_TYPE";
export const PART_SET_PARSED_SERVICE_LIST     = "PART_SET_PARSED_SERVICE_LIST";

export const FETCH_PARTS_FROM_OE_NUMBER       = "FETCH_PARTS_FROM_OE_NUMBER";
export const FETCHING_PARTS                   = "FETCHING_PARTS";
export const PART_LIST_FROM_OE_SUBSCRIPTION   = "PART_LIST_FROM_OE_SUBSCRIPTION";
export const PART_LIST_FROM_OE_DATA_FETCH     = "PART_LIST_FROM_OE_DATA_FETCH";
export const LICENCE_PLATE_CALL_COMPLETED     = "LICENCE_PLATE_CALL_COMPLETED"  
export const FAST_DELIVERY_REQUEST_MADE       = "FAST_DELIVERY_REQUEST_MADE"  


/*-- Parts Action Types Ends --*/


/**-- Watcher -- */
export const SUBSCRIBE_TO_WATCHER = "SUBSCRIBE_TO_WATCHER"
export const CLEAR_WATCHER = "CLEAR_WATCHER"

export const TOGGLE_SIDEBAR_ON_MOBILE   = "TOGGLE_SIDEBAR_ON_MOBILE";

export const UPDATE_INPUT_INFO = "UPDATE_INPUT_INFO";
export const START_FETCHING_FOR_PARTS = "START_FETCHING_FOR_PARTS"
export const UPDATE_CHECKER_DATA = "UPDATE_CHECKER_DATA"
export const UPDATE_RECENT_SEARCH_HISTORY = "UPDATE_RECENT_SEARCH_HISTORY"

/*-- OE readout Action Types Ends --*/
export const DECODING_IN_PROGRESS = "DECODING_IN_PROGRESS";
export const IMAGE_DECODING_COMPLETED = "IMAGE_DECODING_COMPLETED";
export const RESET_OE_READOUT_DATA = "RESET_OE_READOUT_DATA";
export const UPDATE_FILE_DATA = "UPDATE_FILE_DATA"
export const FETCHING_PARTS_IN_PROGRESS = "FETCHING_PARTS_IN_PROGRESS"
export const FETCHING_PART_COMPLETED = "FETCHING_PART_COMPLETED"

/* parts Identifier Action types  */
export const START_FETCHING_OE_NUMBER= "START_FETCHING_OE_NUMBER";
export const UPDATE_PARTS_IDENTIFIER_DATA = "UPDATE_PARTS_IDENTIFIER_DATA";
export const NO_DATA_FOUND = 'NO_DATA_FOUND';
export const UPDATE_PART_SEARCH       = "UPDATE_PART_SEARCH";

/* Vehicle Linker Action Types  */
export const UPDATE_INPUT_DATA = "UPDATE_INPUT_DATA";
export const START_FETCHING_VEHICLE_DATA = "START_FETCHING_VEHICLE_DATA";
export const UPDATE_VEHICLE_DATA = "UPDATE_VEHICLE_DATA";
export const NO_VEHICLE_DATA_FOUND = 'NO_VEHICLE_DATA_FOUND';
export const DATA_NOT_AVAILABLE = 'DATA_NOT_AVAILABLE';

/* Parts Finder Action Types */
export const UPDATE_PF_INPUT_DATA = 'UPDATE_PF_INPUT_DATA';
export const START_FETCHING_PARTS_FINDER_DATA = 'START_FETCHING_PARTS_FINDER_DATA';
export const UPDATE_PF_DATA = 'UPDATE_PF_DATA';
export const PF_DATA_NOT_FOUND = 'PF_DATA_NOT_FOUND';
export const UPDATE_PF_MODEL_DATA = 'UPDATE_PF_MODEL_DATA';

/* Parts Linker 1 */
export const PROCESS_INPUT_DATA =           'PROCESS_INPUT_DATA';
export const START_FETCHING_PARTS_LINKER_1_DATA =  'START_FETCHING_PARTS_LINKER_1_DATA';
export const UPDATE_PART_LINKER_1_DATA = 'UPDATE_PART_LINKER_1_DATA';
export const NO_PART_LINKKER_1_DATA_FOUND = 'NO_PART_LINKKER_1_DATA_FOUND';

/* Download UMC constant */
export const START_FETCHING_UMC_DATA = 'START_FETCHING_UMC_DATA';
export const UPDATE_UMC_DATA = 'UPDATE_UMC_DATA';
export const NO_UMC_DATA = 'NO_UMC_DATA';
export const DOWNLOAD_UMC_DATA_FROM_URL = 'DOWNLOAD_UMC_DATA_FROM_URL';

/* Download UMC constant */
export const START_UPLOADING_VEHICLE_LINK_FILE = 'START_UPLOADING_VEHICLE_LINK_FILE';
export const UPLOAD_COMPLETED = 'UPLOAD_COMPLETED';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
