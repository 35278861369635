import React, { useEffect } from "react";
import { validateRegisterForm } from "../../helpers/Utils";

import { registerInputListGenerator } from "../../constants/signUpForm";
import "../../../../assets/stylesheets/register/register.scss";
import RegisterInput from "./registerInput";

function Register(props) {

  const { actions, loginData, registerData } = props;
  const { validationError } = props.registerData;
  const inputList = registerInputListGenerator(registerData);
  const { isTncChecked, istncCheckboxError } = registerData;
  const translationsData = window.panelConfig.goPartsTranslations.register;

  function setInputState(name, value) {
    const data = {
      name,
      value,
    };
    actions.registerActions.removeInputError(name);
    actions.registerActions.updateRegisterInfo(data);
  }

  function closeRegisterPanel() {
    actions.registerActions.hideRegisterPanel(false);
  }

  function toggleCheckbox() {
    if (istncCheckboxError && !isTncChecked) {
      actions.registerActions.checkboxErrorNotPresent();
    }
    actions.registerActions.toggleCheckbox();
  }

  function openTnc() {
    window.open('/impressum', "_blank");
  }

  function openPrivacyPolicy() {
    window.open('/privacy-policy', "_blank");
  }

  function showLoginPage() {
    actions.registerActions.showLoginPage();
  }

  function signUp() {
    const errors = validateRegisterForm(registerData);
    if (errors.length) {
      actions.registerActions.updateValidationError(errors);
    } else if (!isTncChecked) {
      actions.registerActions.checkboxErrorPresent();
    }
    else {
      actions.registerActions.tryRegisteringUser(registerData);
    }
  }

  return (
    <div className="register-ui-container ">
      <div className="cross-container cp" onClick={e => closeRegisterPanel()}>
        <img height="30px" src="https://d3brnpc5nhvc9v.cloudfront.net/site/static-images/Close-Button.png" alt="Group-20133" border="0" />
      </div>
      <div className="register-field-container">
        <p className="gp-text24 gp-mb20">
          {translationsData.letsStart}
        </p>

        {inputList.map((input, index) => {
          return (
            <RegisterInput
              inputData={input}
              key={index}
              setInputState={setInputState}
              validationError={validationError.includes(input.name)}
            />
          );
        })}

        <div className="card-details-tnc d-flex gp-mt20">
          <span className="cp gp-mt6" onClick={toggleCheckbox}>
            <input type="checkbox" defaultChecked={isTncChecked} />
          </span>
          <p className="go-to-tnc gp-text-16 gp-ml12 gp-text14 gp-lh22 gp-636363">
            <span>{translationsData.agreeTNC}</span>
            <span className="gp-1E8EA5 cp gp-ml6" onClick={openPrivacyPolicy}>
              {translationsData.privacyPolicy}
            </span> zu
          </p>
        </div>
        {istncCheckboxError && (
          <div className="checkbox-error tc">
            <span className="error-text gp-text14 gp-FE696E">
              {translationsData.tncErrorMessage}
            </span>
          </div>
        )}
        {registerData.registrationAttemptFailed && (
          <div className="checkbox-error tc">
            <span className="error-text gp-text14 gp-FE696E">
              We encountered some error at our end. Please try again after some time
            </span>
          </div>
        )}
        <div className="signup-container cp" onClick={() => signUp()}>
          {
            registerData.registrationInProgress ? (
              <span className="panel-sprite  white-loader"></span>
            ) : (
              <span className="ibm tc">  {translationsData.signUp} </span>
            )
          }
        </div>
        <div className="sigin-link tc gp-text16 gp-f400 gp-lh19 gp-mb40" onClick={showLoginPage}>
          <span>{translationsData.alreadyHaveAccount}</span> <span className="gp-1E8EA5 cp">{translationsData.signIn}</span>
        </div>
      </div>
      <div className="copyright-container gp-text12 gp-636363 d-flex">
        <span>{translationsData.copyrightText}</span>
      </div>
    </div>
  );
}

export default Register;
