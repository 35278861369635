import React from "react";
import "../../../../assets/stylesheets/register/register.scss";

function RegistrationSuccess(props) {

  const { actions  } = props;
  const translationsData = window.panelConfig.goPartsTranslations.registrationSucess;


  function closeRegisterPanel() {
    actions.registerActions.hideRegisterPanel(false);  
  }


  return (
    <div className="register-ui-container ">
      <div className="cross-container cp" onClick={e => closeRegisterPanel()}>
        <img height="30px" src="https://d3brnpc5nhvc9v.cloudfront.net/site/static-images/Close-Button.png" alt="Group-20133" border="0" />
      </div>
      <div className="registration-success-container tc gp-lh30">
        <div className="emoiji-container gp-text40 ">
          🥳
        </div>
        <div className="account-creation-message gp-text24 gp-1E8EA5 gp-mt32">
          {translationsData.successfullyRegistered}
        </div>
      </div>
    </div>
  );
}

export default RegistrationSuccess;
