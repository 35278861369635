import React, { useState } from "react";
import { validateLoginForm } from "../../helpers/Utils";

import "../../../../assets/stylesheets/login/login.scss";

function Login(props) {

  const { actions, loginData, registerData } = props;
  const [isPasswordToggled, setPasswordToggled] = useState(false)
  const { validationError } = props.loginData;

  const translationsData = window.panelConfig.goPartsTranslations.login;

  function setInputState(name, value) {
    const data = {
      name,
      value,
    };
    actions.loginActions.removeInputError(name);
    actions.loginActions.updateLoginInfo(data);
  }

  function closeRegisterPanel() {
    actions.registerActions.hideRegisterPanel(false);
  }

  function updatePasswordToggledState() {
    setPasswordToggled(!isPasswordToggled)
  }

  function showRegisterPage() {
    actions.registerActions.showRegisterPage();
  }

  function showForgotPasswordPage() {
    actions.registerActions.showForgotPasswordPage();
  }

  function handleInputChange(event) {
    setInputState(event.target.name, event.target.value);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      logIn();
    }
  }

  function logIn() {
    const errors = validateLoginForm(loginData);
    if (errors.length) {
      actions.loginActions.updateValidationError(errors);
    }
    else {
      actions.loginActions.tryLoggingInUser(loginData);
    }
  }

  return (
    <div className="login-ui-container">
      <div className="cross-container cp" onClick={e => closeRegisterPanel()}>
        <img height="30px" src="https://d3brnpc5nhvc9v.cloudfront.net/site/static-images/Close-Button.png" alt="Group-20133" border="0" />
      </div>
      <div className="login-field-container">
        <p className="gp-text24 gp-mb20 gp-lh30">
          {translationsData.welcomeBack}
        </p>
        <p className="gp-text16 gp-636363 gp-lh162  gp-mb20">
          {translationsData.pleaseLogin}
        </p>
        <div className={"floating-label input-group "}>
          <input
            name="email"
            type="text"
            placeholder={translationsData.emailPlaceholder}
            className={`common-input floating-input ${validationError.includes('email') ? "error-input" : "correct-input"}`}
            onChange={handleInputChange}
            autoComplete="off"
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className={"floating-label input-group gp-mt20"}>
          <input
            name="password"
            type={isPasswordToggled ? 'text' : 'password'}
            placeholder={translationsData.passwordPlaceholder}
            className={`common-input floating-input ${validationError.includes('password') ? "error-input" : "correct-input"}`}
            onChange={handleInputChange}
            autoComplete="off"
            onKeyPress={handleKeyPress}
          />
          <span onClick={(e) => updatePasswordToggledState()}
            className={`fa fa-eye eye-icon cp ${isPasswordToggled ? "gp-1E8EA5" : "gp-636363"}`}>
          </span>
        </div>
        {loginData?.loginAttemptFailed && (
          <div className="checkbox-error tc gp-mt20 gp-mb20">
            <span className="error-text gp-text14 gp-FE696E">
             {translationsData.loginAttemptFailed}
            </span>
          </div>
        )}
        <div className="signup-container cp" onClick={() => logIn()}>
          {  loginData?.loginProgress && <span className="panel-sprite white-loader"></span> }
            { !loginData?.loginProgress && (
              <span className="ibm tc"> {translationsData.login} </span>
            )
          }
        </div>
        <div className="sigin-link gp-mt20 tc gp-text16 gp-f400 gp-lh19" onClick={showRegisterPage}>
          <span>{translationsData.dontHaveAccount}</span> <span className="gp-1E8EA5 cp">{translationsData.register}</span>
        </div>
        <div className="gp-mt20 sigin-link tc gp-text16 gp-f400 gp-lh19" onClick={showForgotPasswordPage}>
          <span className="gp-1E8EA5 cp">{translationsData.forgotPassword}</span>
        </div>
      </div>
      <div className="copyright-container gp-text12 gp-636363 d-flex">
        <span>{translationsData.copyrightText}</span>
      </div>
    </div>
  );
}

export default Login;
