import * as actionTypes from '../constants/ActionTypes';
import * as endpoints from '../constants/Endpoints';
import * as utils from '../helpers/Utils';

export function updateLoginInfo ( data ) {
	return { type: actionTypes.UPDATE_LOGIN_INFO, data };
}

export function updateValidationError ( data ) {
	return { type: actionTypes.UPDATE_VALIDATION_ERROR, data }
}

export function removeInputError ( data ) {
	return { type: actionTypes.REMOVE_INPUT_ERROR, data }
}

export function loginInProgress(data) {
  return { type: actionTypes.LOGIN_IN_PROGRESS, data };
}

export function onLoginCallComplete(data) {
  return { type: actionTypes.LOGIN_CALL_COMPLETE, data };
}

export function forgetPWDInProgress(data) {
  return { type: actionTypes.FORGET_PWD_IN_PROGRESS, data };
}

export function forgetPWDCallComplete(data) {
  return { type: actionTypes.FORGET_PWD_CALL_COMPLETE, data };
}

export function onlogoutSuccess( data ){
  return {type: actionTypes.LOGOUT_COMPLETE, data};
}

export function resetPasswordInProgress( data ){
  return {type: actionTypes.RESET_IN_PROGRESS, data};
}

export function onResetCallComplete( data ){
  return {type: actionTypes.RESET_CALL_COMPLETE, data};
}

export function tryLoggingInUser(data) {
  return function (dispatch) {
    const dataToBeSend = {
      "email": data.email,
      "password": data.password
    };

    dispatch(loginInProgress())

    let headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'X-CUSTOMER-TOKEN' : '',
    };

    data && data.email && fetch(endpoints.USER_LOGIN_API, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(dataToBeSend)
    })
      .then(response => response.json())
      .then(jsonResponse => {

        let response      = jsonResponse,
            isSuccess     = response && response.status && response.status.status == "SUCCESS" ? true : false,
            responseData  = response.payload || {};

        dispatch(onLoginCallComplete(jsonResponse));

        if( isSuccess ){
          let nextPage        = endpoints.PANEL_DASHBOARD_URL;

          localStorage.setItem( "loginAppToken", responseData.token );
          utils.CookiesHelper.setItem( "loginAppToken", responseData.token, 604800, endpoints.PANEL_ROOT_URL);
          localStorage.setItem("userSession", JSON.stringify( responseData.user_info ) );

          setTimeout( function(){
            window.location.href = nextPage;
          }, 10);
        }

      })
      .catch(ex => dispatch(onLoginCallComplete({ex})));
  }
}

export function tryForgotPassword(data) {

  return function (dispatch) {
    const dataToBeSend = {
        "email": data.email
    };

    dispatch(forgetPWDInProgress())

    let headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    };

    data && data.email && fetch(endpoints.FORGET_PASSWORD_API, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(dataToBeSend)
    })
      .then(response => response.json())
      .then(jsonResponse => {

        dispatch(forgetPWDCallComplete(jsonResponse));

      })
      .catch(ex => dispatch(forgetPWDCallComplete({})));
  }
}

export function logoutUser( data ){
  return function (dispatch) {
    const loginToken          = localStorage.getItem( "loginAppToken");
    
    localStorage.setItem( "loginAppToken", '' );
    utils.CookiesHelper.setItem( "loginAppToken", "", "", endpoints.PANEL_ROOT_URL );
    localStorage.setItem( "userSession", '' );

    let headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'X-CUSTOMER-TOKEN' : loginToken,
    };

    fetch( endpoints.USER_LOGOUT_API, {
      method: 'post',
      headers: headers,
      body: "{}"
    })
      .then( response => response.json() )
      .then( jsonResponse =>{
        dispatch( onlogoutSuccess(data) )
        setTimeout( function(){
          window.location.href = endpoints.PANEL_ROOT_URL;
        }, 100);
      })
      .catch( ex => {
        dispatch( onlogoutSuccess(data) )
        setTimeout( function(){
          window.location.href = endpoints.PANEL_ROOT_URL;
        }, 100);
      });
  };
}

export function tryResetPassword( data ){
  
  return function (dispatch) {
    dispatch( resetPasswordInProgress(data) );

    let headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'X-CUSTOMER-TOKEN' : '',
    };
    
    fetch( endpoints.UPDATE_PASSWORD_API , {
      method: 'post',
      headers: headers,
      body: JSON.stringify( data )
    })
    .then( response => response.json() )
    .then( jsonResponse =>{
      dispatch( onResetCallComplete(jsonResponse) )
    })
    .catch( ex => dispatch( onResetCallComplete({}) ) );
  }

}
