import * as actionTypes from '../constants/ActionTypes';
import * as endpoints from '../constants/Endpoints';

export function updateRegisterInfo(data) {
  return { type: actionTypes.UPDATE_REGISTER_INFO, data };
}

export function updateValidationError(data) {
  return { type: actionTypes.UPDATE_VALIDATION_ERROR, data }
}

export function resetValidationError() {
  return { type: actionTypes.RESET_VALIDATION_ERROR }
}

export function removeInputError(data) {
  return { type: actionTypes.REMOVE_INPUT_ERROR, data }
}

export function hideRegisterPanel(data) {
  return { type: actionTypes.HIDE_REGISTER_PANEL, data }
}

export function checkboxErrorPresent(data) {
  return { type: actionTypes.CHECKBOX_ERROR_PRESENT, data };
}

export function checkboxErrorNotPresent(data) {
  return { type: actionTypes.CHECKBOX_ERROR_NOT_PRESENT, data };
}

export function toggleCheckbox(data) {
  return { type: actionTypes.TOGGLE_CHECKBOX, data };
}

export function showLoginPage(data) {
  return { type: actionTypes.SHOW_LOGIN_PAGE, data };
}

export function showRegisterPage(data) {
  return { type: actionTypes.SHOW_REGISTER_PAGE, data };
}

export function registrationInProgress(data) {
  return { type: actionTypes.REGISTRATION_IN_PROGRESS, data };
}

export function onRegistrationCallComplete(data) {
  return { type: actionTypes.REGISTRATION_CALL_COMPLETE, data };
}

export function showForgotPasswordPage(data) {
  return { type: actionTypes.SHOW_FORGOTPWD_PAGE, data };
}

export function showResetPasswordPage(data) {
  return { type: actionTypes.SHOW_RESET_PWD_PAGE, data };
}

export function tryRegisteringUser(data) {

  return function (dispatch) {
    const dataToBeSend = {
      "customer": {
        "name": data.name,
        "company_name": data.comp_name,
        "email": data.email,
        "password": data.password,
        "number": data.phone,
        "address": data.address,
        "zip": data.plz,
        "city": data.city,
        "country": data.country
      }
    };

    dispatch(registrationInProgress())

    let headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    };

    data && data.email && fetch(endpoints.USER_SIGNUP_API, {
      method: 'post',
      headers: headers,
      body: JSON.stringify(dataToBeSend)
    })
      .then(response => response.json())
      .then(jsonResponse => {

        dispatch(onRegistrationCallComplete(jsonResponse));

      })
      .catch(ex => dispatch(onRegistrationCallComplete({})));
  }
}
