
export const PANEL_BE_URL                          = window.panelConfig.partsAppUrl || "";
export const PANEL_BE_PARTS_URL                    = window.panelConfig.gtPartsUrl || "";
export const PANEL_DASHBOARD_URL                   = "dashboard/service-overview";
export const PANEL_ROOT_URL                        = "/";


export const USER_SIGNUP_API                       = PANEL_BE_URL + "/api/v1/sessions/signup";
export const USER_LOGIN_API                        = PANEL_BE_URL + "/api/v1/sessions/login";
export const FORGET_PASSWORD_API                   = PANEL_BE_URL + "/api/v1/passwords/forgot"
export const UPDATE_PASSWORD_API                   = PANEL_BE_URL + "/api/v1/passwords/change";
export const USER_LOGOUT_API                       = PANEL_BE_URL + "/api/v1/sessions/logout"

export const FE_SERVICE_OVERVIEW                   = PANEL_ROOT_URL + "dashboard/service-overview";
export const FE_PARTS_CHECKER                      = PANEL_ROOT_URL + "dashboard/parts-checker";
export const FE_PARTS_IDENTIFIER                   = PANEL_ROOT_URL + "dashboard/parts-identifier-";
export const FE_PARTS_IDENTIFIER2                   = PANEL_ROOT_URL + "dashboard/parts-identifier";
export const FE_SETTINGS                           = PANEL_ROOT_URL + "dashboard/settings";
export const FE_NOTIFICATIONS                      = PANEL_ROOT_URL + "dashboard/notifications";

export const GP_PARTS_CHECKER                      = PANEL_BE_URL + "/api/v1/gt/parts_checker";
export const PARTS_CHECKER_LOGIN_API               = PANEL_BE_URL + "/api/v1/gt/client_verification";
export const GP_PARTS_IDENTIFIER                   = PANEL_BE_URL + "/api/v1/gt/parts_identifier";
export const GP_VEHICLE_LINKER                     = PANEL_BE_URL + "/api/v1/gt/vehicle_linker";
export const GP_PARTS_LINKER_1                     = PANEL_BE_URL + '/api/v1/gt/parts_linker';
export const GP_PARTS_FINDER                       = PANEL_BE_URL + '/api/v1/gt/parts_finder';
export const GP_PARTS_GET_EQUIPMENT_LIST           = PANEL_BE_URL + '/api/v1/gt/equipment_list';
export const GP_PARTS_GET_UMC_LIST                 = PANEL_BE_URL + '/api/v1/gt/umc_list';
export const GP_PARTS_GET_PARTS_LIST               = PANEL_BE_URL + '/api/v1/gt/parts_list';

export const PUSHER_AUTH                           = PANEL_BE_PARTS_URL + "/api/partners/pusher_auth";


export const PART_GT_LIST_ASYNC_PARTNER_API        = PANEL_BE_PARTS_URL + `/api/partners/${window.panelConfig.partnerName}/get_parts_list_async`;
export const PART_GT_OE_ASYNC_PARTNER_API          = PANEL_BE_PARTS_URL + `/api/partners/${window.panelConfig.partnerName}/get_oe_number_async`;
export const PART_GT_LIST_ASYNC_API                = PANEL_BE_PARTS_URL + `/api/v1/gt/get_parts_list_async`;
export const PART_GT_OE_ASYNC_API                  = PANEL_BE_PARTS_URL + `/api/v1/gt/get_oe_number_async`;
export const PART_LIST_FROM_OE_ASYNC_API           = PANEL_BE_PARTS_URL + "/api/v1/parts_list/";


export const emailException                        = PANEL_BE_PARTS_URL + "/api/v1/emails/send_fe_exception_email";

export const OCR_DECODER_API                       = PANEL_BE_URL + '/api/v1/ocr_decoder/decode_image'
export const UPLOAD_VEHICLE_LINKING_API            = PANEL_BE_URL + '/api/v1/gt/upload_vehicle_linking_data'